<template>
  <div class="upcoming-workshop-card relative">
    <div class="text-size-20 workshop-title">
      {{ workshop.title }}
    </div>
    <div class="workshop-date flex items-center justify-center flex-col">
      <div class="day text-size-36 lora-bold">{{ day(workshop.date) }}</div>
      <div class="year">{{ month(workshop.date) }}</div>
      <div class="year">{{ year(workshop.date) }}</div>
    </div>
    <div
      class="
        mt-24
        group-cards
        mb-4
        grid
        items-center
        grid-cols-1
        sm:grid-cols-2
        gap-x-1
      "
    >
      <div class="title flex flex-wrap items-center me-2">
        <div class="me-4 my-1">
          {{ content.groupOf }} {{ format(ticket.min) }}-{{
            format(ticket.max)
          }}
          {{ content.attendees }}
        </div>
      </div>
      <div class="flex flex-row flex-wrap items-center justify-between">
        <div class="price flex items-center my-1 me-2 whitespace-nowrap">
          {{ format(ticket.price) }} {{ content.iqd }}
        </div>
        <p>{{ date }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "/src/assets/numberFormat";

export default {
  inject: ["content"],
  props: ["order"],
  computed: {
    ticket() {
      return this.order.ticket;
    },
    workshop() {
      return this.ticket.workshop;
    },
    date() {
      return `${this.order.date.split(",")[2]}/${
        this.order.date.split(",")[1]
      }/${this.order.date.split(",")[0]}`;
    },
  },
  methods: {
    year(date) {
      return date.split(",")[0];
    },
    month(date) {
      return date.split(",")[1];
    },
    day(date) {
      return date.split(",")[2];
    },
    format(number) {
      return format(number);
    },
  },
};
</script>