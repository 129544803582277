<template>
  <div class="layout-padding" v-if="order">
    <PageTitle
      class="my-8"
      :title="content.order + ' #' + format(id)"
      :childOne="content.account"
      :childTwo="content.orderHistory"
    />
    <div class="my-5 grid grid-cols-12 gap-x-0 md:gap-x-10">
      <div class="col-span-12 lg:col-span-8 mb-12">
        <TicketCard :order="order" />
      </div>
      <div class="col-span-12 lg:col-span-4">
        <OrderSummery
          :status="order.status"
          :date="order.date"
          :subTotal="order.ticket.price"
          :delivery="order.city?.price ?? 0"
          :total="total"
          :paid="order.paid"
          @pay="payLater"
        >
        </OrderSummery>
        <!-- <OrderShipping :order="order" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "/src/assets/numberFormat";
import OrderSummery from "/src/components/ui/Order/OrderSummery.vue";
// import OrderShipping from "../../../components/ui/Order/OrderShipping.vue";
import TicketCard from "../../../components/ui/Order/TicketCard.vue";

export default {
  components: {
    OrderSummery,
    // OrderShipping,
    TicketCard,
  },
  props: ["id"],
  inject: ["content"],
  async mounted() {
    await this.$store.dispatch("workshops/myTickets");

    if (!this.$store.getters["workshops/ticket"](this.id)) {
      this.$router.replace("/account/orderHistory");
    }
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    order() {
      return this.$store.getters["workshops/ticket"](this.id);
      // let order = 'products'

      // return order;
    },
    date() {
      return `${this.order.date.split(",")[2]}/${
        this.order.date.split(",")[1]
      }/${this.order.date.split(",")[0]}`;
    },
    total() {
      return this.order.city
        ? parseFloat(this.order.ticket.price) +
            parseFloat(this.order.city.price)
        : this.order.ticket.price;
    },
  },
  methods: {
    format(number) {
      return format(number);
    },
    async payLater(method) {
      try {
        this.$store.dispatch("paylater/pay", {
          id: this.id,
          type: "ticket",
          method: method,
        });
      } catch (error) {
        this.$store.dispatch("feedback/setFeedback", {
          type: "fail",
          title: this.content.fail,
          message: this.content.errorOccured,
        });
      }
    },
  },
};
</script>
